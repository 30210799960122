import React from "react";
import { Link } from "gatsby";
import { Container } from "react-bootstrap";
import styled from "styled-components";
import Section from "../components/Section";
import PageWrapper from "../components/PageWrapper";

import imgIcon from "../assets/image/png/thumbs-shape.png";
import { Title, Text, Button } from "../components/Core";

const ContentIcon = styled.div`
  width: 118px;
  height: 118px;
  background-color:#0e9ae5;
  border-radius: 500px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 48px;
`;


const NotFoundPage = () => {
  return (
    <>
      <PageWrapper>
        <Section>
          <div className="pt-5"></div>
          <Container>
            <div className="text-center">
              <ContentIcon>
                <img src={imgIcon} alt="" className="img-fluid" />
              </ContentIcon>
              <div>
                <Title variant="hero">Thank You</Title>
                <Text>
                  Thankyou for your submission! We will be in contact with you soon. 
                  <br className="d-none d-md-block" /> If your matter is urgent, feel free to give us a call.
                </Text>
              </div>
              <div className="mt-5">
                <a href="tel: 0422 727 511">
                  <Button bg="#0e9ae5" borderRadius="500px">Call Now</Button>
                </a>
              </div>

              <div className="mt-5">
                <Link to="/">
                  <Button bg="#0e9ae5" borderRadius="500px">Go back to home</Button>
                </Link>
              </div>
            </div>
          </Container>
        </Section>
      </PageWrapper>
    </>
  );
};

export default NotFoundPage;
